<template>
  <div>
     <div v-if="showTaskFlag">
        <previewTaskShow v-if="showTaskFlag==1" style="height:calc(100vh)" :taskID="currenttaskID"></previewTaskShow>
        <pdfpreviewShow v-if="showTaskFlag==2" style="height:calc(100vh);overflow-y:auto" :taskID="currenttaskID"></pdfpreviewShow>
        <previewVideoShow v-if="showTaskFlag==3" :sourceId="taskInfo.sourceId"></previewVideoShow>         
        <previewPygameShow v-if="showTaskFlag==4" style="height:calc(100vh)" :sourceId="taskInfo.sourceId"  :taskInfo='taskInfo'></previewPygameShow>         
        <previewScratchJrShow v-if="showTaskFlag==5" style="height:calc(100vh)" :sourceId="taskInfo.sourceId"  :taskInfo='taskInfo'></previewScratchJrShow>         
    </div>
    <div v-else style="text-align:center;margin-top:100px;font-size:16px">
      暂无内容
    </div>
  </div>
</template>

<script>
  import previewTaskShow from '@/componentsTeacher/previewTask'
  import pdfpreviewShow from '@/componentsTeacher/pdfpreview'
  import previewVideoShow from '@/componentsTeacher/previewVideo'
  import previewPygameShow from "@/views/Pygame/pregive";
  import previewScratchJrShow from '@/views/ScratchJr/prepare'

export default {
  components: {previewTaskShow,pdfpreviewShow,previewVideoShow,previewPygameShow,previewScratchJrShow},
  data () {
    return {
      currenttaskID: '',
      showTaskFlag: 0,
      height: 0,
      taskInfo:{},
    }
  },
  watch:{
    showTaskFlag:{
      handler(val){
        this.showTaskFlag=val;
      },
      immediate: true,
    }
  },
  created () {
    this.height = window.innerHeight + 'px';
    this.currenttaskID = this.$route.query.currenttaskID;
    this.showTaskFlag = this.$route.query.showTaskFlag;
    this.taskInfo = JSON.parse(this.$route.query.taskInfo);
  }
}
</script>
