var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showTaskFlag
      ? _c(
          "div",
          [
            _vm.showTaskFlag == 1
              ? _c("previewTaskShow", {
                  staticStyle: { height: "calc(100vh)" },
                  attrs: { taskID: _vm.currenttaskID },
                })
              : _vm._e(),
            _vm.showTaskFlag == 2
              ? _c("pdfpreviewShow", {
                  staticStyle: { height: "calc(100vh)", "overflow-y": "auto" },
                  attrs: { taskID: _vm.currenttaskID },
                })
              : _vm._e(),
            _vm.showTaskFlag == 3
              ? _c("previewVideoShow", {
                  attrs: { sourceId: _vm.taskInfo.sourceId },
                })
              : _vm._e(),
            _vm.showTaskFlag == 4
              ? _c("previewPygameShow", {
                  staticStyle: { height: "calc(100vh)" },
                  attrs: {
                    sourceId: _vm.taskInfo.sourceId,
                    taskInfo: _vm.taskInfo,
                  },
                })
              : _vm._e(),
            _vm.showTaskFlag == 5
              ? _c("previewScratchJrShow", {
                  staticStyle: { height: "calc(100vh)" },
                  attrs: {
                    sourceId: _vm.taskInfo.sourceId,
                    taskInfo: _vm.taskInfo,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "margin-top": "100px",
              "font-size": "16px",
            },
          },
          [_vm._v(" 暂无内容 ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }